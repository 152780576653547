@if(report && report.data.length > 0) {
<div class="scroll-container mt-2" #tableContainer>
  <table class="table-dynamic text-sm text-left rtl:text-right w-full whitespace-nowrap">
    <thead #tableHeader>
      <tr class="text-sm td-second-b-border">
        <th scope="col" class="sticky-col first-col min-w-28 py-4 px-4 border-b border-tableBorderColor theader-text bg-white"
          (mouseenter)="setHoveredCell(-1, 0)" (mouseleave)="clearHoveredCell()">
        </th>
        <th scope="col"
          class="sticky-col second-col min-w-28 py-4 px-4 border-b border-tableBorderColor text-right theader-text bg-white"
          (mouseenter)="setHoveredCell(-1, 1)" (mouseleave)="clearHoveredCell()">
        </th>
        @for(data of report.header; track $index; let i = $index) {
        <th scope="col" class="min-w-28 py-4 px-4 border-b border-tableBorderColor text-right theader-text"
          [ngClass]="{
            'bg-gray-200': hoveredCol === i + 2,
            'bg-white': hoveredCol !== i + 2}"
          (mouseenter)="setHoveredCell(-1, i + 2)" (mouseleave)="clearHoveredCell()">
          {{data.year}}{{data.year !== '同期合計' && data.year !== '合計' ? '/' : ''}}{{data.month}}
        </th>
        }
      </tr>
    </thead>
    <tbody #tableContent>
      @for(place of report.data; track $index; let i = $index) {
      @if(place.rowspan > 1){
      @if(report.showBudget) {
      <tr
        [ngClass]="{'bg-gray-200': hoveredRow === i * (report.showBudget ? 5 : 4), 'bg-white': hoveredRow !== i * (report.showBudget ? 5 : 4)}">
        <td class="sticky-col first-col py-2.5 px-4 min-w-28"
          [ngClass]="{'bg-gray-200': hoveredCol === 0 || isRowInHoverRange(i, hoveredRow), 'bg-white': hoveredCol !== 0 && !isRowInHoverRange(i, hoveredRow)}"
          (mouseenter)="setHoveredCell(i * (report.showBudget ? 5 : 4), 0)" (mouseleave)="clearHoveredCell()">
        </td>
        <td class="sticky-col second-col px-4 py-2.5 td-primary-b-border min-w-28"
          [ngClass]="{'bg-gray-200': hoveredCol === 1 || hoveredRow === i * (report.showBudget ? 5 : 4), 'bg-white': hoveredCol !== 1 && hoveredRow !== i * (report.showBudget ? 5 : 4)}"
          (mouseenter)="setHoveredCell(i * (report.showBudget ? 5 : 4), 1)" (mouseleave)="clearHoveredCell()">
          預算
        </td>
        @for(data of place.data; track $index; let j = $index){
        <td class="px-4 py-2.5 px-4 td-primary-b-border text-right"
          [ngClass]="{
          'bg-gray-200': hoveredCol === j + 2 || hoveredRow === i * (report.showBudget ? 5 : 4),
          'bg-white': hoveredCol !== j + 2 && hoveredRow !== i * (report.showBudget ? 5 : 4),
          'font-bold': j === (place.data.length - 1) || i === (report.data.length - 1)}"
          (mouseenter)="setHoveredCell(i * (report.showBudget ? 5 : 4), j + 2)" (mouseleave)="clearHoveredCell()">
          {{ data.currBudgetAmount | number:'1.0-0' }}
        </td>
        }
      </tr>
      }
      <tr
        [ngClass]="{'bg-gray-200': hoveredRow === i * (report.showBudget ? 5 : 4) + 1, 'bg-white': hoveredRow !== i * (report.showBudget ? 5 : 4) + 1}">
        <td class="sticky-col first-col py-2.5 px-4 min-w-28" [ngClass]="{
                'bg-gray-200': hoveredCol === 0 || isRowInHoverRange(i, hoveredRow),
                'bg-white': hoveredCol !== 0 && !isRowInHoverRange(i, hoveredRow)}"
          (mouseenter)="setHoveredCell(i * (report.showBudget ? 5 : 4) + 1, 0)" (mouseleave)="clearHoveredCell()">
        </td>
        <td class="sticky-col second-col px-4 py-2.5 td-primary-b-border min-w-28"
          [ngClass]="{
                'bg-tableLighter': report.showBudget,
                '!bg-gray-200': hoveredCol === 1 || hoveredRow === i * (report.showBudget ? 5 : 4) + 1,
                'bg-white': hoveredCol !== 1 && hoveredRow !== i * (report.showBudget ? 5 : 4) + 1 && !report.showBudget}" (mouseenter)="setHoveredCell(i * (report.showBudget ? 5 : 4) + 1, 1)"
          (mouseleave)="clearHoveredCell()">
          實際金額
        </td>
        @for(data of place.data; track $index; let j = $index){
        <td class="px-4 py-2.5 td-primary-b-border text-right min-w-28"
          [ngClass]="{
            'bg-tableLighter': report.showBudget,
            '!bg-gray-200': (hoveredCol === j + 2 || hoveredRow === i * (report.showBudget ? 5 : 4) + 1),
            'bg-white': hoveredCol !== j + 2 && hoveredRow !== i * (report.showBudget ? 5 : 4) + 1 && !report.showBudget,
            'font-bold': j === (place.data.length - 1)|| i === (report.data.length - 1)}"
          (mouseenter)="setHoveredCell(i * (report.showBudget ? 5 : 4) + 1, j + 2)" (mouseleave)="clearHoveredCell()">
          {{ data.currSalesAmount | number:'1.0-0' }}
        </td>
        }
      </tr>
      @if(report.showBudget) {
      <tr
        [ngClass]="{'bg-gray-200': hoveredRow === i * (report.showBudget ? 5 : 4) + 2, 'bg-white': hoveredRow !== i * (report.showBudget ? 5 : 4) + 2}">
        <td class="sticky-col first-col py-2.5 px-4 min-w-28"
          [ngClass]="{'
          bg-gray-200': hoveredCol === 0 || isRowInHoverRange(i, hoveredRow),
          'bg-white': hoveredCol !== 0 && !isRowInHoverRange(i, hoveredRow),
          'font-bold': report.showBudget}"
          (mouseenter)="setHoveredCell(i * (report.showBudget ? 5 : 4) + 2, 0)" (mouseleave)="clearHoveredCell()">
          {{ place.rowName }}
        </td>
        <td class="sticky-col second-col px-4 py-2.5 td-primary-b-border min-w-28"
          [ngClass]="{'bg-gray-200': hoveredCol === 1 || hoveredRow === i * (report.showBudget ? 5 : 4) + 2, 'bg-white': hoveredCol !== 1 && hoveredRow !== i * (report.showBudget ? 5 : 4) + 2}"
          (mouseenter)="setHoveredCell(i * (report.showBudget ? 5 : 4) + 2, 1)" (mouseleave)="clearHoveredCell()">
          達成率
        </td>
        @for(data of place.data; track $index; let j = $index){
        <td class="px-4 py-2.5 td-primary-b-border text-right min-w-28"
          [ngClass]="{
            'bg-gray-200': hoveredCol === j + 2 || hoveredRow === i * (report.showBudget ? 5 : 4) + 2,
            'bg-white': hoveredCol !== j + 2 && hoveredRow !== i * (report.showBudget ? 5 : 4) + 2,
            'font-bold': j === (place.data.length - 1)|| i === (report.data.length - 1)}"
          (mouseenter)="setHoveredCell(i * (report.showBudget ? 5 : 4) + 2, j + 2)" (mouseleave)="clearHoveredCell()">
          {{ data.progressRate | customPercent }}
        </td>
        }
      </tr>
      }
      <tr
        [ngClass]="{'bg-gray-200': hoveredRow === i * (report.showBudget ? 5 : 4) + (report.showBudget ? 3 : 2), 'bg-white': hoveredRow !== i * (report.showBudget ? 5 : 4) + (report.showBudget ? 3 : 2)}">
        <td class="sticky-col first-col py-2.5 px-4 min-w-28"
          [ngClass]="{
            'bg-gray-200': hoveredCol === 0 || isRowInHoverRange(i, hoveredRow),
            'bg-white': hoveredCol !== 0 && !isRowInHoverRange(i, hoveredRow),
            'font-bold': !report.showBudget}"
          (mouseenter)="setHoveredCell(i * (report.showBudget ? 5 : 4) + (report.showBudget ? 3 : 2), 0)"
          (mouseleave)="clearHoveredCell()">
          {{report.showBudget ? '' : place.rowName }}
        </td>
        <td class="sticky-col second-col px-4 py-2.5 td-primary-b-border min-w-28"
          [ngClass]="{'bg-tableLighter': true, '!bg-gray-200': hoveredCol === 1 || hoveredRow === i * (report.showBudget ? 5 : 4) + (report.showBudget ? 3 : 2), 'bg-white': hoveredCol !== 1 && hoveredRow !== i * (report.showBudget ? 5 : 4) + (report.showBudget ? 3 : 2)}"
          (mouseenter)="setHoveredCell(i * (report.showBudget ? 5 : 4) + (report.showBudget ? 3 : 2), 1)"
          (mouseleave)="clearHoveredCell()">
          同期比較
        </td>
        @for(data of place.data; track $index; let j = $index){
        <td class="px-4 py-2.5 td-primary-b-border text-right min-w-28"
          [ngClass]="{
            'bg-tableLighter': true,
            '!bg-gray-200': hoveredCol === j + 2 || hoveredRow === i * (report.showBudget ? 5 : 4) + (report.showBudget ? 3 : 2),
            'bg-white': hoveredCol !== j + 2 && hoveredRow !== i * (report.showBudget ? 5 : 4) + (report.showBudget ? 3 : 2),
            'font-bold': j === (place.data.length - 1)|| i === (report.data.length - 1)}"
          (mouseenter)="setHoveredCell(i * (report.showBudget ? 5 : 4) + (report.showBudget ? 3 : 2), j + 2)"
          (mouseleave)="clearHoveredCell()">
          {{ data.preSalesAmount | number:'1.0-0' }}
        </td>
        }
      </tr>
      <tr class="td-second-b-border border-tableBorderColor"
        [ngClass]="{'bg-gray-200': hoveredRow === i * (report.showBudget ? 5 : 4) + (report.showBudget ? 4 : 3), 'bg-white': hoveredRow !== i * (report.showBudget ? 5 : 4) + (report.showBudget ? 4 : 3)}">
        <td class="sticky-col first-col py-2.5 px-4 min-w-28" [ngClass]="{
              'bg-gray-200': hoveredCol === 0 || isRowInHoverRange(i, hoveredRow),
              'bg-white': hoveredCol !== 0 && !isRowInHoverRange(i, hoveredRow)}"
          (mouseenter)="setHoveredCell(i * (report.showBudget ? 5 : 4) + (report.showBudget ? 4 : 3), 0)"
          (mouseleave)="clearHoveredCell()">
        </td>
        <td class="sticky-col second-col px-4 py-2.5 td-primary-b-border min-w-28"
          [ngClass]="{'bg-gray-200': hoveredCol === 1 || hoveredRow === i * (report.showBudget ? 5 : 4) + (report.showBudget ? 4 : 3), 'bg-white': hoveredCol !== 1 && hoveredRow !== i * (report.showBudget ? 5 : 4) + (report.showBudget ? 4 : 3)}"
          (mouseenter)="setHoveredCell(i * (report.showBudget ? 5 : 4) + (report.showBudget ? 4 : 3), 1)"
          (mouseleave)="clearHoveredCell()">
          成長率
        </td>
        @for(data of place.data; track $index; let j = $index){
        <td class="px-4 py-2.5 td-primary-b-border text-right min-w-28"
          [ngClass]="{
            'bg-gray-200': hoveredCol === j + 2 || hoveredRow === i * (report.showBudget ? 5 : 4) + (report.showBudget ? 4 : 3),
            'bg-white': hoveredCol !== j + 2 && hoveredRow !== i * (report.showBudget ? 5 : 4) + (report.showBudget ? 4 : 3),
            'font-bold': j === (place.data.length - 1)|| i === (report.data.length - 1)}"
          (mouseenter)="setHoveredCell(i * (report.showBudget ? 5 : 4) + (report.showBudget ? 4 : 3), j + 2)"
          (mouseleave)="clearHoveredCell()">
          {{ data.growthRate | customPercent }}
        </td>
        }
      </tr>
      }
      }
    </tbody>
  </table>
</div>
}
