import { Component, ElementRef, Renderer2, ViewChild} from '@angular/core';
import { FilterConfig, FilterConfigOptions } from '../../../../../shared/shared.models';
import { BehaviorSubject, forkJoin, map } from 'rxjs';
import { ReportService } from '../../services/report.service';
import { InputFiltersComponent } from '../../../../../shared/components/input-filters/input-filters.component';
import { PerformanceGroup, ReportFilter, PlaceGroupsWithPlaces, DateRangeCalculator, PlacesInPlaceGroup, TotalSalesPerformance} from '../../report.models';
import { MultiSelectFiltersComponent } from '../../../../../shared/components/multi-select-filters/multi-select-filters.component';
import { EmailComponent } from '../../email/email.component';
import { SalesTableComponent } from '../sales-table/sales-table.component';
import { LoadingSpinnerComponent } from '../../../../../shared/components/loading-spinner/loading-spinner.component';

@Component({
  selector: 'app-sales-display',
  standalone: true,
  imports: [InputFiltersComponent, MultiSelectFiltersComponent, SalesTableComponent, EmailComponent, LoadingSpinnerComponent],
  templateUrl: './sales-display.component.html'
})
export class SalesDisplayComponent {

  loading: boolean = false;
  inputs: FilterConfig[];
  years: FilterConfigOptions[];
  months: FilterConfigOptions[];

  clients: PlaceGroupsWithPlaces[];
  selectedClients: PlacesInPlaceGroup[];

  selectedPerformanceFilter: ReportFilter = new ReportFilter();
  currentPerformance: PerformanceGroup[] = [];
  currentPerformance$: BehaviorSubject<PerformanceGroup[]> = new BehaviorSubject<PerformanceGroup[]>([]);

  currentYearPermanceFilter: ReportFilter;
  currentYearPerformance: PerformanceGroup[] = [];

  preYearPermanceFilter: ReportFilter;
  preYearPerformance: PerformanceGroup[] = [];

  constructor(
    private reportService: ReportService
  ) {

    //取得客戶(類別)資料 傳遞給multi-select-filters
    this.reportService.getCustomersGroupDetails(false)
    .subscribe((data) => {
      data.forEach((item) => {
        item.placeVMs.forEach((place) => {
          place.isSelected = false;
        });
        item.placeVMs.unshift({ placeCode: 'ALL', placeName: '全部', placeGroupName: item.placeGroupName, isSelected: false });
      });

      this.clients = data;

    });

    //訂閱業績資料
    this.currentPerformance$.subscribe(data => {
      this.currentPerformance = data;
    });
  }


  ngOnInit() {
    this.getSelectedPerformaceDate();
  }

  //取得篩選日期
  getSelectedPerformaceDate(item?: ReportFilter) {
    this.selectedPerformanceFilter.fromYear = item?.fromYear ? item.fromYear : new Date().getFullYear().toString();
    this.selectedPerformanceFilter.fromMonth = item?.fromMonth  ? item.fromMonth  : (new Date().getMonth() + 1).toString().padStart(2, '0');
    this.selectedPerformanceFilter.toYear = item?.toYear ? item.toYear : new Date().getFullYear().toString();
    this.selectedPerformanceFilter.toMonth =  item?.toMonth ? item.toMonth : (new Date().getMonth() + 1).toString().padStart(2, '0');
    let calculator = new DateRangeCalculator(Number(this.selectedPerformanceFilter.fromYear), Number(this.selectedPerformanceFilter.fromMonth));
    this.currentYearPermanceFilter = calculator.getFinancialYearRange();
    this.preYearPermanceFilter = calculator.getPreviousFinancialYearRange();
    this.setOptions();
  }

  //設定日期選項傳入input-filters
  setOptions() {
    forkJoin({
      years: this.reportService.getYears().pipe(
        map(y => y.map(y => ({ value: y })))),
      months: this.reportService.getMonths().pipe(
        map( m => m.map( m => ({ value: m })))),
    })
    .subscribe(({ years, months}) => {
      this.years = years || [];
      this.months = months || [];
      this.inputs = [
        { key: 'fromYear', type: 'select-single', label: '年(起)', options: this.years, defualtValue: this.selectedPerformanceFilter.fromYear},
        { key: 'fromMonth', type: 'select-single', label: '月(起)', options: this.months, defualtValue: this.selectedPerformanceFilter.fromMonth},
        { key: 'toYear', type: 'select-single', label: '年(訖)', options: this.years, defualtValue: this.selectedPerformanceFilter.toYear},
        { key: 'toMonth', type: 'select-single', label: '月(訖)', options: this.months, defualtValue: this.selectedPerformanceFilter.toMonth},
      ];
    });
  }

  //更新篩選日期
  async updateSelectedDate(item: { fromYear: string, fromMonth: string, toYear: string, toMonth: string }) {
    this.getSelectedPerformaceDate({ fromYear: item.fromYear, fromMonth: item.fromMonth, toYear: item.toYear, toMonth: item.toMonth });
  }

  //更新客戶
  async updateSelectedClient(item: any){
    this.selectedClients = this.filterPerformanceGroups(item);
  }

  //取得業績
  async setPerformance() {
    this.loading = true;

    this.selectedPerformanceFilter.placeGroups = this.selectedClients;
    const currentPerformance = await this.getPerformance(this.selectedPerformanceFilter);
    this.currentPerformance = this.createNewPerformance(currentPerformance);

    this.currentYearPermanceFilter.placeGroups = this.selectedClients;
    const currentYearPerformance = await this.getPerformance(this.currentYearPermanceFilter);
    this.currentYearPerformance = this.createNewPerformance(currentYearPerformance);

    this.preYearPermanceFilter.placeGroups = this.selectedClients;
    const preYearPerformance = await this.getPerformance(this.preYearPermanceFilter);
    this.preYearPerformance = this.createNewPerformance(preYearPerformance);

    this.loading = false;
  }

  // 建立新業績
  createNewPerformance(performance: PerformanceGroup[]) {
    return performance.map((item) => ({
      ...item,
      performance: new TotalSalesPerformance()
    }));
  }



  //取得業績API
  getPerformance(item: ReportFilter): Promise<PerformanceGroup[]>{
    return this.reportService.takeSalePerformanceData(item);
  }

  // 篩選選取客戶
  filterPerformanceGroups(placeGroups: PlaceGroupsWithPlaces[]): PlacesInPlaceGroup[] {
    return placeGroups.map(group => ({
      placeGroupCode: group.placeGroupCode,
      places: group.placeVMs.filter(place => place.isSelected && place.placeCode !== 'ALL').map(place => place.placeCode)
    })).filter(group => group.places.length > 0);
  }

}
