import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: 'customPercent',
  standalone: true
})

export class CustomPercentPipe implements PipeTransform {
  transform(value: number | null): string {
    if (value === null) {
      return '0';
    } else {
      const percentValue = value * 100;
      if (percentValue % 1 === 0) {
        return `${percentValue}%`;
      } else {
        return `${percentValue.toFixed(2)}%`;
      }
    }

  }
}
