import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpRequest, HttpInterceptor } from '@angular/common/http';
import { Observable, from, firstValueFrom } from 'rxjs';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { switchMap } from 'rxjs/operators';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  constructor(private oidcSecurityService: OidcSecurityService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return from(this.getUpdatedToken()).pipe(
      switchMap(token => {
        if (token) {
          const headers = req.headers.set('Authorization', `Bearer ${token}`);
          const authReq = req.clone({ headers });
          return next.handle(authReq);
        }
        return next.handle(req);
      })
    );
  }

  private async getUpdatedToken(): Promise<string | null> {
    try {
      const isAuthenticated = await firstValueFrom(this.oidcSecurityService.isAuthenticated());

      if (!isAuthenticated) {
        return null;
      }

      const token = await firstValueFrom(this.oidcSecurityService.getAccessToken());

      if (!token) {
        return null;
      }

      return token;
    } catch (error) {
      console.error('取得token發生錯誤:', error);
      return null;
    }
  }
}
