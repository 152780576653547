<div class="scroll-container mt-2">
  <div class="table-wrapper" #scrollContainer>
    <table class="table-dynamic scroll-area text-sm text-left rtl:text-right w-full whitespace-nowrap">
      <thead class="bg-white">
        <tr class="text-sm">
          <th [ngStyle]="{'width': dynamicColWidth}" [ngStyle]="{'width': dynamicColWidth}" [ngClass]="{
            'bg-gray-200': isHighlighted(null, -1, 0),
            'bg-white': !isHighlighted(null, -1, 0)
          }" (mouseenter)="onCellHover(null, -1, 0)" (mouseleave)="onCellHover(null, null, null)"
            class="sticky left-0 min-w-32 z-10 lg:min-w-24 w-32 lg:w-24 font-medium pt-2 pb-4 text-center td-primary-b-border rounded-tl-lg bg-white">
          </th>
          <th [ngStyle]="{'width': dynamicColWidth}" [ngClass]="{'bg-gray-200': isHighlighted(null, -1, 1)}"
            (mouseenter)="onCellHover(null, -1, 1)" (mouseleave)="onCellHover(null, null, null)"
            class="min-w-32 lg:min-w-24 w-32 lg:w-24 font-medium pt-2 pb-4 px-4 text-right td-primary-b-border"
            (click)="setSort('currBudgetAmount')">
            <div class="flex justify-end items-center cursor-pointer theader-text">
              <svg class="w-3 h-3 text-[#757575] mr-1 font-bold" fill="none" viewBox="0 0 24 24" stroke-width="3.5"
                stroke="currentColor"
                [ngClass]="{'rotate-180': sortColumn === 'currBudgetAmount' && sortOrder === 'desc'}">
                <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 13.5 12 21m0 0-7.5-7.5M12 21V3" />
              </svg>
              預算
            </div>
          </th>
          <th [ngStyle]="{'width': dynamicColWidth}" [ngClass]="{'bg-gray-200': isHighlighted(null, -1, 2)}"
            (mouseenter)="onCellHover(null, -1, 2)" (mouseleave)="onCellHover(null, null, null)"
            class="min-w-32 lg:min-w-24 w-32 lg:w-24 font-medium pt-2 pb-4 px-4 text-right td-primary-b-border"
            (click)="setSort('currSalesAmount')">
            <div class="flex justify-end items-center cursor-pointer theader-text">
              <svg class="w-3 h-3 text-[#757575] mr-1 font-bold" fill="none" viewBox="0 0 24 24" stroke-width="3.5"
                stroke="currentColor"
                [ngClass]="{'rotate-180': sortColumn === 'currSalesAmount' && sortOrder === 'desc'}">
                <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 13.5 12 21m0 0-7.5-7.5M12 21V3" />
              </svg>
              實際業績
            </div>
          </th>
          <th [ngStyle]="{'width': dynamicColWidth}" [ngClass]="{'bg-gray-200': isHighlighted(null, -1, 3)}"
            (mouseenter)="onCellHover(null, -1, 3)" (mouseleave)="onCellHover(null, null, null)"
            class="min-w-32 lg:min-w-24 w-32 lg:w-24 font-medium pt-2 pb-4 px-4 text-right td-primary-b-border"
            (click)="setSort('currDifference')">
            <div class="flex justify-end items-center cursor-pointer theader-text">
              <svg class="w-3 h-3 text-[#757575] mr-1 font-bold" fill="none" viewBox="0 0 24 24" stroke-width="3.5"
                stroke="currentColor"
                [ngClass]="{'rotate-180': sortColumn === 'currDifference' && sortOrder === 'desc'}">
                <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 13.5 12 21m0 0-7.5-7.5M12 21V3" />
              </svg>
              差異
            </div>
          </th>
          <th [ngStyle]="{'width': dynamicColWidth}" [ngClass]="{'bg-gray-200': isHighlighted(null, -1, 4)}"
            (mouseenter)="onCellHover(null, -1, 4)" (mouseleave)="onCellHover(null, null, null)"
            class="min-w-32 lg:min-w-24 w-32 lg:w-24 font-medium pt-2 pb-4 px-4 text-right td-primary-b-border"
            (click)="setSort('currProgressRate')">
            <div class="flex justify-end items-center cursor-pointer theader-text">
              <svg class="w-3 h-3 text-[#757575] mr-1 font-bold" fill="none" viewBox="0 0 24 24" stroke-width="3.5"
                stroke="currentColor"
                [ngClass]="{'rotate-180': sortColumn === 'currProgressRate' && sortOrder === 'desc'}">
                <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 13.5 12 21m0 0-7.5-7.5M12 21V3" />
              </svg>
              達成率
            </div>
          </th>
          <th [ngStyle]="{'width': dynamicColWidth}" [ngClass]="{'bg-gray-200': isHighlighted(null, -1, 5)}"
            (mouseenter)="onCellHover(null, -1, 5)" (mouseleave)="onCellHover(null, null, null)"
            class="min-w-32 lg:min-w-24 w-32 lg:w-24 font-medium pt-2 pb-4 px-4 text-right td-primary-b-border"
            (click)="setSort('preSalesAmount')">
            <div class="flex justify-end items-center cursor-pointer theader-text">
              <svg class="w-3 h-3 text-[#757575] mr-1 font-bold" fill="none" viewBox="0 0 24 24" stroke-width="3.5"
                stroke="currentColor"
                [ngClass]="{'rotate-180': sortColumn === 'preSalesAmount' && sortOrder === 'desc'}">
                <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 13.5 12 21m0 0-7.5-7.5M12 21V3" />
              </svg>
              去年同期
            </div>
          </th>
          <th [ngStyle]="{'width': dynamicColWidth}" [ngClass]="{'bg-gray-200': isHighlighted(null, -1, 6)}"
            (mouseenter)="onCellHover(null, -1, 6)" (mouseleave)="onCellHover(null, null, null)"
            class="min-w-32 lg:min-w-24 w-32 lg:w-24 font-medium pt-2 pb-4 px-4 text-right td-primary-b-border"
            (click)="setSort('preDifference')">
            <div class="flex justify-end items-center cursor-pointer theader-text">
              <svg class="w-3 h-3 text-[#757575] mr-1 font-bold" fill="none" viewBox="0 0 24 24" stroke-width="3.5"
                stroke="currentColor"
                [ngClass]="{'rotate-180': sortColumn === 'preDifference' && sortOrder === 'desc'}">
                <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 13.5 12 21m0 0-7.5-7.5M12 21V3" />
              </svg>
              去年差異
            </div>
          </th>
          <th [ngStyle]="{'width': dynamicColWidth}" [ngClass]="{'bg-gray-200': isHighlighted(null, -1, 7)}"
            (mouseenter)="onCellHover(null, -1, 7)" (mouseleave)="onCellHover(null, null, null)"
            class="min-w-32 lg:min-w-24 w-32 lg:w-24 font-medium pt-2 pb-4 px-4 text-right td-primary-b-border"
            (click)="setSort('growthRate')">
            <div class="flex justify-end items-center cursor-pointer theader-text">
              <svg class="w-3 h-3 text-[#757575] mr-1 font-bold" fill="none" viewBox="0 0 24 24" stroke-width="3.5"
                stroke="currentColor" [ngClass]="{'rotate-180': sortColumn === 'growthRate' && sortOrder === 'desc'}">
                <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 13.5 12 21m0 0-7.5-7.5M12 21V3" />
              </svg>
              成長率
            </div>
          </th>
          <th [ngStyle]="{'width': dynamicColWidth}" [ngClass]="{'bg-gray-200': isHighlighted(null, -1, 8)}"
            (mouseenter)="onCellHover(null, -1, 8)" (mouseleave)="onCellHover(null, null, null)"
            class="min-w-32 lg:min-w-24 w-32 lg:w-24 font-medium pt-2 pb-4 px-4 text-right td-primary-b-border rounded-tr-lg"
            (click)="setSort('salesProportion')">
            <div class="flex justify-end items-center cursor-pointer theader-text">
              <svg class="w-3 h-3 text-[#757575] mr-1 font-bold" fill="none" viewBox="0 0 24 24" stroke-width="3.5"
                stroke="currentColor"
                [ngClass]="{'rotate-180': sortColumn === 'salesProportion' && sortOrder === 'desc'}">
                <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 13.5 12 21m0 0-7.5-7.5M12 21V3" />
              </svg>
              占比
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        @for(item of data; track $index; let i = $index) {
        <tr (click)="toggleSubDetails(item)" class="bg-white cursor-pointer">
          <td [ngStyle]="{'width': dynamicColWidth}" [ngClass]="{
              'bg-gray-200': isHighlighted(i, 0, 0),
              'bg-white': !isHighlighted(i, 0, 0)
            }" (mouseenter)="onCellHover(i, 0, 0)" (mouseleave)="onCellHover(null, null, null)"
            class="sticky left-0 min-w-32 lg:min-w-24 w-32 py-2.5 px-4 td-primary-b-border font-bold">
            @if(shouldDisplayDetails(item)){
            <svg class="h-4 w-4 font-bol inline-block mr-2" viewBox="0 0 24 24" fill="none" stroke="currentColor"
              stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
              <polyline points="6 9 12 15 18 9" />
            </svg>
            } @else {
            <span class="px-3 inline-block"></span>
            }
            {{item.department}}
          </td>
          <td [ngStyle]="{'width': dynamicColWidth}" [ngClass]="{'bg-gray-200': isHighlighted(i, 0, 1, false)}"
            (mouseenter)="onCellHover(i, 0, 1, false)" (mouseleave)="onCellHover(null, null, null)"
            class="min-w-32 lg:min-w-24 w-32 py-2.5 px-4 td-primary-b-border text-right font-bold">
            {{item.performance.currBudgetAmount ? (item.performance.currBudgetAmount | number:'1.0-0') : '0'}}
          </td>
          <td [ngStyle]="{'width': dynamicColWidth}" [ngClass]="{'bg-gray-200': isHighlighted(i, 0, 2, false)}"
            (mouseenter)="onCellHover(i, 0, 2, false)" (mouseleave)="onCellHover(null, null, null)"
            class="min-w-32 lg:min-w-24 w-32 py-2.5 px-4 td-primary-b-border text-right font-bold">
            {{item.performance.currSalesAmount ? (item.performance.currSalesAmount| number:'1.0-0') : '0'}}
          </td>
          <td [ngStyle]="{'width': dynamicColWidth}" [ngClass]="{'bg-gray-200': isHighlighted(i, 0, 3, false)}"
            (mouseenter)="onCellHover(i, 0, 3, false)" (mouseleave)="onCellHover(null, null, null)"
            class="min-w-32 lg:min-w-24 w-32 py-2.5 px-4 td-primary-b-border text-right font-bold">
            {{item.performance.currDifference ? (item.performance.currDifference | number:'1.0-0') : '0'}}
          </td>
          <td [ngStyle]="{'width': dynamicColWidth}" [ngClass]="{'bg-gray-200': isHighlighted(i, 0, 4, false)}"
            (mouseenter)="onCellHover(i, 0, 4, false)" (mouseleave)="onCellHover(null, null, null)"
            class="min-w-32 lg:min-w-24 w-32 py-2.5 px-4 td-primary-b-border text-right font-bold">
            {{item.performance.currProgressRate | customPercent}}
          </td>
          <td [ngStyle]="{'width': dynamicColWidth}" [ngClass]="{'bg-gray-200': isHighlighted(i, 0, 5, false)}"
            (mouseenter)="onCellHover(i, 0, 5, false)" (mouseleave)="onCellHover(null, null, null)"
            class="min-w-32 lg:min-w-24 w-32 py-2.5 px-4 td-primary-b-border text-right font-bold">
            {{item.performance.preSalesAmount ? (item.performance.preSalesAmount | number:'1.0-0') : '0'}}
          </td>
          <td [ngStyle]="{'width': dynamicColWidth}" [ngClass]="{'bg-gray-200': isHighlighted(i, 0, 6, false)}"
            (mouseenter)="onCellHover(i, 0, 6, false)" (mouseleave)="onCellHover(null, null, null)"
            class="min-w-32 lg:min-w-24 w-32 py-2.5 px-4 td-primary-b-border text-right font-bold">
            {{item.performance.preDifference ? (item.performance.preDifference | number:'1.0-0') : '0'}}
          </td>
          <td [ngStyle]="{'width': dynamicColWidth}" [ngClass]="{'bg-gray-200': isHighlighted(i, 0, 7, false)}"
            (mouseenter)="onCellHover(i, 0, 7, false)" (mouseleave)="onCellHover(null, null, null)"
            class="min-w-32 lg:min-w-24 w-32 py-2.5 px-4 td-primary-b-border text-right font-bold">
            {{item.performance.growthRate | customPercent}}
          </td>
          <td [ngStyle]="{'width': dynamicColWidth}" [ngClass]="{'bg-gray-200': isHighlighted(i, 0, 8, false)}"
            (mouseenter)="onCellHover(i, 0, 8, false)" (mouseleave)="onCellHover(null, null, null)"
            class="min-w-32 lg:min-w-24 w-32 py-2.5 px-4 td-primary-b-border text-right font-bold">
            {{item.performance.salesProportion ?? 0 | customPercent}}
          </td>
        </tr>

        @if(item.details.length > 0 && item.performance.isToggle) {
        <tr class="text-sm">
          <td colspan="9">
            <table class="w-full">
              <tbody>
                @for(detail of item.details; track $index; let j = $index) {
                @if(item.details.length > 0 ) {
                <tr>
                  <td [ngStyle]="{'width': dynamicColWidth}" [ngClass]="{
                    'bg-gray-200': isHighlighted(i, j, 0, true),
                    'bg-tableLighter': !isHighlighted(i, j, 0, true) && (j+1) % 2 !== 0,
                    'bg-white': !isHighlighted(i, j, 0, true) && (j+1) % 2 === 0
                  }" (mouseenter)="onCellHover(i, j, 0, true)" (mouseleave)="onCellHover(null, null, null)"
                    class="sticky left-0 min-w-32 lg:min-w-24 w-32 lg:w-24 py-2.5 px-4 td-primary-b-border font-bold">
                    <span class="inline-block ml-9">{{detail.placeGroupName}}</span>
                  </td>
                  <td [ngStyle]="{'width': dynamicColWidth}" [ngClass]="{
                      'bg-gray-200': isHighlighted(i, j, 1, true),
                      'bg-tableLighter': !isHighlighted(i, j, 1, true) && (j+1) % 2 !== 0,
                      'bg-white': !isHighlighted(i, j, 1, true) && (j+1) % 2 === 0
                    }" (mouseenter)="onCellHover(i, j, 1, true)" (mouseleave)="onCellHover(null, null, null)"
                    class="min-w-32 lg:min-w-24 w-32 py-2.5 px-4 td-primary-b-border text-right">
                    {{detail.currBudgetAmount ? (detail.currBudgetAmount| number:'1.0-0') : '0'}}
                  </td>
                  <td [ngStyle]="{'width': dynamicColWidth}" [ngClass]="{
                      'bg-gray-200': isHighlighted(i, j, 2, true),
                      'bg-tableLighter': !isHighlighted(i, j, 2, true) && (j+1) % 2 !== 0,
                      'bg-white': !isHighlighted(i, j, 2, true) && (j+1) % 2 === 0
                    }" (mouseenter)="onCellHover(i, j, 2, true)" (mouseleave)="onCellHover(null, null, null)"
                    class="min-w-32 lg:min-w-24 w-32 py-2.5 px-4 td-primary-b-border text-right">
                    {{detail.currSalesAmount ? (detail.currSalesAmount| number:'1.0-0') : '0'}}
                  </td>
                  <td [ngStyle]="{'width': dynamicColWidth}" [ngClass]="{
                      'bg-gray-200': isHighlighted(i, j, 3, true),
                      'bg-tableLighter': !isHighlighted(i, j, 3, true) && (j+1) % 2 !== 0,
                      'bg-white': !isHighlighted(i, j, 3, true) && (j+1) % 2 === 0
                    }" (mouseenter)="onCellHover(i, j, 3, true)" (mouseleave)="onCellHover(null, null, null)"
                    class="min-w-32 lg:min-w-24 w-32 py-2.5 px-4 td-primary-b-border text-right">
                    {{detail.currDifference ? (detail.currDifference | number:'1.0-0') : '0'}}
                  </td>
                  <td [ngStyle]="{'width': dynamicColWidth}" [ngClass]="{
                      'bg-gray-200': isHighlighted(i, j, 4, true),
                      'bg-tableLighter': !isHighlighted(i, j, 4, true) && (j+1) % 2 !== 0,
                      'bg-white': !isHighlighted(i, j, 4, true) && (j+1) % 2 === 0
                    }" (mouseenter)="onCellHover(i, j, 4, true)" (mouseleave)="onCellHover(null, null, null)"
                    class="min-w-32 lg:min-w-24 w-32 py-2.5 px-4 td-primary-b-border text-right">
                    {{detail.currProgressRate | customPercent}}
                  </td>
                  <td [ngStyle]="{'width': dynamicColWidth}" [ngClass]="{
                      'bg-gray-200': isHighlighted(i, j, 5, true),
                      'bg-tableLighter': !isHighlighted(i, j, 5, true) && (j+1) % 2 !== 0,
                      'bg-white': !isHighlighted(i, j, 5, true) && (j+1) % 2 === 0
                    }" (mouseenter)="onCellHover(i, j, 5, true)" (mouseleave)="onCellHover(null, null, null)"
                    class="min-w-32 lg:min-w-24 w-32 py-2.5 px-4 td-primary-b-border text-right">
                    {{detail.preSalesAmount ? (detail.preSalesAmount | number:'1.0-0') : '0'}}
                  </td>
                  <td [ngStyle]="{'width': dynamicColWidth}" [ngClass]="{
                      'bg-gray-200': isHighlighted(i, j, 6, true),
                      'bg-tableLighter': !isHighlighted(i, j, 6, true) && (j+1) % 2 !== 0,
                      'bg-white': !isHighlighted(i, j, 6, true) && (j+1) % 2 === 0
                    }" (mouseenter)="onCellHover(i, j, 6, true)" (mouseleave)="onCellHover(null, null, null)"
                    class="min-w-32 lg:min-w-24 w-32 py-2.5 px-4 td-primary-b-border text-right">
                    {{detail.preDifference ? (detail.preDifference | number:'1.0-0') : '0'}}
                  </td>
                  <td [ngStyle]="{'width': dynamicColWidth}" [ngClass]="{
                      'bg-gray-200': isHighlighted(i, j, 7, true),
                      'bg-tableLighter': !isHighlighted(i, j, 7, true) && (j+1) % 2 !== 0,
                      'bg-white': !isHighlighted(i, j, 7, true) && (j+1) % 2 === 0
                    }" (mouseenter)="onCellHover(i, j, 7, true)" (mouseleave)="onCellHover(null, null, null)"
                    class="min-w-32 lg:min-w-24 w-32 py-2.5 px-4 td-primary-b-border text-right">
                    {{detail.growthRate | customPercent}}
                  </td>
                  <td [ngStyle]="{'width': dynamicColWidth}" [ngClass]="{
                      'bg-gray-200': isHighlighted(i, j, 8, true),
                      'bg-tableLighter': !isHighlighted(i, j, 8, true) && (j+1) % 2 !== 0,
                      'bg-white': !isHighlighted(i, j, 8, true) && (j+1) % 2 === 0
                    }" (mouseenter)="onCellHover(i, j, 8, true)" (mouseleave)="onCellHover(null, null, null)"
                    class="min-w-32 lg:min-w-24 w-32 py-2.5 px-4 td-primary-b-border text-right">
                    {{detail.salesProportion ?? 0 | customPercent}}
                  </td>
                </tr>
                }
                }
              </tbody>
            </table>
          </td>
        </tr>
        }
        }
        <tr class="font-bold bg-white">
          <td [ngStyle]="{'width': dynamicColWidth}" [ngClass]="{
                  'bg-gray-200': isHighlighted(-1, 0, 0),
                  'bg-white': !isHighlighted(-1, 0, 0)
                }" (mouseenter)="onCellHover(-1, 0, 0)" (mouseleave)="onCellHover(null, null, null)"
            class="sticky left-0 min-w-32 lg:min-w-24 w-32 td-primary-b-border py-2.5 px-4">
            <span class="px-3 inline-block"></span>合計
          </td>
          <td [ngStyle]="{'width': dynamicColWidth}" [ngClass]="{'bg-gray-200': isHighlighted(-1, 0, 1)}"
            (mouseenter)="onCellHover(-1, 0, 1)" (mouseleave)="onCellHover(null, null, null)"
            class="min-w-32 lg:min-w-24 w-32 py-2.5 px-4 text-right td-primary-b-border">
            {{sumPerformance.currBudgetAmount ? (sumPerformance.currBudgetAmount| number:'1.0-0') : '0' }}
          </td>
          <td [ngStyle]="{'width': dynamicColWidth}" [ngClass]="{'bg-gray-200': isHighlighted(-1, 0, 2)}"
            (mouseenter)="onCellHover(-1, 0, 2)" (mouseleave)="onCellHover(null, null, null)"
            class="min-w-32 lg:min-w-24 w-32 py-2.5 px-4 text-right td-primary-b-border">
            {{sumPerformance.currSalesAmount ? (sumPerformance.currSalesAmount| number:'1.0-0') : '0' }}
          </td>
          <td [ngStyle]="{'width': dynamicColWidth}" [ngClass]="{'bg-gray-200': isHighlighted(-1, 0, 3)}"
            (mouseenter)="onCellHover(-1, 0, 3)" (mouseleave)="onCellHover(null, null, null)"
            class="min-w-32 lg:min-w-24 w-32 py-2.5 px-4 text-right td-primary-b-border">
            {{sumPerformance.currDifference ? (sumPerformance.currDifference| number:'1.0-0') : '0'}}
          </td>
          <td [ngStyle]="{'width': dynamicColWidth}" [ngClass]="{'bg-gray-200': isHighlighted(-1, 0, 4)}"
            (mouseenter)="onCellHover(-1, 0, 4)" (mouseleave)="onCellHover(null, null, null)"
            class="min-w-32 lg:min-w-24 w-32 py-2.5 px-4 text-right td-primary-b-border">
            {{sumPerformance.currProgressRate| customPercent}}
          </td>
          <td [ngStyle]="{'width': dynamicColWidth}" [ngClass]="{'bg-gray-200': isHighlighted(-1, 0, 5)}"
            (mouseenter)="onCellHover(-1, 0, 5)" (mouseleave)="onCellHover(null, null, null)"
            class="min-w-32 lg:min-w-24 w-32 py-2.5 px-4 text-right td-primary-b-border">
            {{sumPerformance.preSalesAmount ? (sumPerformance.preSalesAmount| number:'1.0-0') : '0'}}
          </td>
          <td [ngStyle]="{'width': dynamicColWidth}" [ngClass]="{'bg-gray-200': isHighlighted(-1, 0, 6)}"
            (mouseenter)="onCellHover(-1, 0, 6)" (mouseleave)="onCellHover(null, null, null)"
            class="min-w-32 lg:min-w-24 w-32 py-2.5 px-4 text-right td-primary-b-border">
            {{sumPerformance.preDifference ? (sumPerformance.preDifference| number:'1.0-0') : '0'}}
          </td>
          <td [ngStyle]="{'width': dynamicColWidth}" [ngClass]="{'bg-gray-200': isHighlighted(-1, 0, 7)}"
            (mouseenter)="onCellHover(-1, 0, 7)" (mouseleave)="onCellHover(null, null, null)"
            class="min-w-32 lg:min-w-24 w-32 py-2.5 px-4 text-right td-primary-b-border">
            {{sumPerformance.growthRate | customPercent}}
          </td>
          <td [ngStyle]="{'width': dynamicColWidth}" [ngClass]="{'bg-gray-200': isHighlighted(-1, 0, 8)}"
            (mouseenter)="onCellHover(-1, 0, 8)" (mouseleave)="onCellHover(null, null, null)"
            class="min-w-32 lg:min-w-24 w-32 py-2.5 px-4 rounded-br-lg text-right td-primary-b-border">
            {{sumPerformance.salesProportion ?? 0 | customPercent}}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
