@if(tableData){
  <div class="table-container mt-2" #tableContainer>
    <table class="table-dynamic text-sm text-left rtl:text-right w-full whitespace-nowrap">
      <thead #tableHeader class="bg-white">
        <tr class="text-sm td-second-b-border">
          <th scope="col"
              class="sticky-col first-col min-w-32 px-4 py-4 border-b border-tableBorderColor theader-text bg-white"
              (mouseenter)="setHoveredCell(-1, 0)"
              (mouseleave)="clearHoveredCell()">
          </th>
          <th scope="col"
              class="sticky-col second-col min-w-32 px-4 py-4 border-b border-tableBorderColor text-righ theader-text bg-white"
              (mouseenter)="setHoveredCell(-1, 1)"
              (mouseleave)="clearHoveredCell()">
          </th>
          @for(product of header; track $index) {
            @if(product.productGroupName !== '全部') {
              <th scope="col"
                  class="min-w-32 py-4 px-4 border-b border-tableBorderColor text-right theader-text"
                  [ngClass]="{'bg-gray-200': hoveredCol === getDisplayIndex($index) + 2, 'bg-white': hoveredCol !== getDisplayIndex($index) + 2}"
                  (mouseenter)="setHoveredCell(-1, getDisplayIndex($index) + 2)"
                  (mouseleave)="clearHoveredCell()">
                {{ product.productGroupName }}
              </th>
            }
          }
        </tr>
      </thead>
      <tbody #tableContent>
        @for(place of tableData; track $index; let i = $index) {
          @if(place.rowspan > 1){
            @if(showBudget) {
              <tr [ngClass]="{'bg-gray-200': hoveredRow === i * (showBudget ? 5 : 4), 'bg-white': hoveredRow !== i * (showBudget ? 5 : 4)}">
                <td class="sticky-col first-col py-2.5 px-4 min-w-32"
                    [ngClass]="{'bg-gray-200': hoveredCol === 0 || isRowInHoverRange(i, hoveredRow), 'bg-white': hoveredCol !== 0 && !isRowInHoverRange(i, hoveredRow)}"
                    (mouseenter)="setHoveredCell(i * (showBudget ? 5 : 4), 0)"
                    (mouseleave)="clearHoveredCell()">
                </td>
                <td class="sticky-col second-col py-2.5 px-4 td-primary-b-border min-w-32"
                    [ngClass]="{'bg-gray-200': hoveredCol === 1 || hoveredRow === i * (showBudget ? 5 : 4), 'bg-white': hoveredCol !== 1 && hoveredRow !== i * (showBudget ? 5 : 4)}"
                    (mouseenter)="setHoveredCell(i * (showBudget ? 5 : 4), 1)"
                    (mouseleave)="clearHoveredCell()">
                  預算
                </td>
                @for(data of place.productsData; track $index; let j = $index){
                  <td class=" py-2.5 px-4 td-primary-b-border text-right"
                      [ngClass]="{
                        'bg-gray-200': hoveredCol === j + 2 || hoveredRow === i * (showBudget ? 5 : 4),
                        'bg-white': hoveredCol !== j + 2 && hoveredRow !== i * (showBudget ? 5 : 4),
                        'font-bold': j === (place.productsData.length - 1)|| i === (tableData.length - 1)}"
                      (mouseenter)="setHoveredCell(i * (showBudget ? 5 : 4), j + 2)"
                      (mouseleave)="clearHoveredCell()">
                    {{ data.currBudgetAmount | number:'1.0-0' }}
                  </td>
                }
              </tr>
            }
            <tr [ngClass]="{'bg-gray-200': hoveredRow === i * (showBudget ? 5 : 4) + 1, 'bg-white': hoveredRow !== i * (showBudget ? 5 : 4) + 1}">
              <td class="sticky-col first-col py-2.5 px-4 min-w-32"
                  [ngClass]="{'bg-gray-200': hoveredCol === 0 || isRowInHoverRange(i, hoveredRow), 'bg-white': hoveredCol !== 0 && !isRowInHoverRange(i, hoveredRow)}"
                  (mouseenter)="setHoveredCell(i * (showBudget ? 5 : 4) + 1, 0)"
                  (mouseleave)="clearHoveredCell()">
              </td>
              <td class="sticky-col second-col py-2.5 px-4 td-primary-b-border min-w-32"
                  [ngClass]="{
                    'bg-tableLighter': showBudget && hoveredCol !== 1 && hoveredRow !== i * (showBudget ? 5 : 4) + 1,
                    'bg-gray-200': hoveredCol === 1 || hoveredRow === i * (showBudget ? 5 : 4) + 1,
                    'bg-white': !showBudget && hoveredCol !== 1 && hoveredRow !== i * (showBudget ? 5 : 4) + 1
                  }"
                  (mouseenter)="setHoveredCell(i * (showBudget ? 5 : 4) + 1, 1)"
                  (mouseleave)="clearHoveredCell()">
                實際金額
              </td>
              @for(data of place.productsData; track $index; let j = $index){
                <td class=" py-2.5 px-4 td-primary-b-border text-right min-w-32"
                    [ngClass]="{
                      'bg-tableLighter': showBudget && hoveredCol !== j + 2 && hoveredRow !== i * (showBudget ? 5 : 4) + 1,
                      'bg-gray-200': hoveredCol === j + 2 || hoveredRow === i * (showBudget ? 5 : 4) + 1,
                      'bg-white': !showBudget && hoveredCol !== j + 2 && hoveredRow !== i * (showBudget ? 5 : 4) + 1,
                      'font-bold': j === (place.productsData.length - 1)|| i === (tableData.length - 1)
                    }"
                    (mouseenter)="setHoveredCell(i * (showBudget ? 5 : 4) + 1, j + 2)"
                    (mouseleave)="clearHoveredCell()">
                  {{ data.currSalesAmount | number:'1.0-0' }}
                </td>
              }
            </tr>
            @if(showBudget) {
              <tr [ngClass]="{'bg-gray-200': hoveredRow === i * (showBudget ? 5 : 4) + 2, 'bg-white': hoveredRow !== i * (showBudget ? 5 : 4) + 2}">
                <td class="sticky-col first-col py-2.5 px-4 min-w-32 font-bold"
                    [ngClass]="{'bg-gray-200': hoveredCol === 0 || isRowInHoverRange(i, hoveredRow), 'bg-white': hoveredCol !== 0 && !isRowInHoverRange(i, hoveredRow)}"
                    (mouseenter)="setHoveredCell(i * (showBudget ? 5 : 4) + 2, 0)"
                    (mouseleave)="clearHoveredCell()">
                  {{place.placeName}}
                </td>
                <td class="sticky-col second-col py-2.5 px-4 td-primary-b-border min-w-32"
                    [ngClass]="{'bg-gray-200': hoveredCol === 1 || hoveredRow === i * (showBudget ? 5 : 4) + 2, 'bg-white': hoveredCol !== 1 && hoveredRow !== i * (showBudget ? 5 : 4) + 2}"
                    (mouseenter)="setHoveredCell(i * (showBudget ? 5 : 4) + 2, 1)"
                    (mouseleave)="clearHoveredCell()">
                  達成率
                </td>
                @for(data of place.productsData; track $index; let j = $index){
                  <td class=" py-2.5 px-4 td-primary-b-border text-right min-w-32"
                      [ngClass]="{
                        'bg-gray-200': hoveredCol === j + 2 || hoveredRow === i * (showBudget ? 5 : 4) + 2,
                        'bg-white': hoveredCol !== j + 2 && hoveredRow !== i * (showBudget ? 5 : 4) + 2,
                        'font-bold': j === (place.productsData.length - 1)|| i === (tableData.length - 1)}"
                      (mouseenter)="setHoveredCell(i * (showBudget ? 5 : 4) + 2, j + 2)"
                      (mouseleave)="clearHoveredCell()">
                    {{ data.progressRate | customPercent }}
                  </td>
                }
              </tr>
            }
            <tr [ngClass]="{'bg-gray-200': hoveredRow === i * (showBudget ? 5 : 4) + (showBudget ? 3 : 2), 'bg-white': hoveredRow !== i * (showBudget ? 5 : 4) + (showBudget ? 3 : 2)}">
              <td class="sticky-col first-col py-2.5 px-4 min-w-32"
                  [ngClass]="{
                    'bg-gray-200': hoveredCol === 0 || isRowInHoverRange(i, hoveredRow),
                    'bg-white': hoveredCol !== 0 && !isRowInHoverRange(i, hoveredRow),
                    'font-bold': !showBudget
                  }"
                  (mouseenter)="setHoveredCell(i * (showBudget ? 5 : 4) + (showBudget ? 3 : 2), 0)"
                  (mouseleave)="clearHoveredCell()">
                {{ showBudget ? '' : place.placeName }}
              </td>
              <td class="sticky-col second-col py-2.5 px-4 td-primary-b-border min-w-32"
                  [ngClass]="{
                    'bg-tableLighter': hoveredCol !== 1 && hoveredRow !== i * (showBudget ? 5 : 4) + (showBudget ? 3 : 2),
                    'bg-gray-200': hoveredCol === 1 || hoveredRow === i * (showBudget ? 5 : 4) + (showBudget ? 3 : 2),
                  }"
                  (mouseenter)="setHoveredCell(i * (showBudget ? 5 : 4) + (showBudget ? 3 : 2), 1)"
                  (mouseleave)="clearHoveredCell()">
                同期比較
              </td>
              @for(data of place.productsData; track $index; let j = $index){
                <td class=" py-2.5 px-4 td-primary-b-border text-right min-w-32"
                    [ngClass]="{
                      'bg-tableLighter': hoveredCol !== j + 2 && hoveredRow !== i * (showBudget ? 5 : 4) + (showBudget ? 3 : 2),
                      'bg-gray-200': hoveredCol === j + 2 || hoveredRow === i * (showBudget ? 5 : 4) + (showBudget ? 3 : 2),
                      'font-bold': j === (place.productsData.length - 1)|| i === (tableData.length - 1)
                    }"
                    (mouseenter)="setHoveredCell(i * (showBudget ? 5 : 4) + (showBudget ? 3 : 2), j + 2)"
                    (mouseleave)="clearHoveredCell()">
                  {{ data.preSalesAmount | number:'1.0-0' }}
                </td>
              }
            </tr>
            <tr class="border-b-2 border-tableBorderColor" [ngClass]="{'bg-gray-200': hoveredRow === i * (showBudget ? 5 : 4) + (showBudget ? 4 : 3), 'bg-white': hoveredRow !== i * (showBudget ? 5 : 4) + (showBudget ? 4 : 3)}">
              <td class="sticky-col first-col py-2.5 px-4 min-w-32"
                  [ngClass]="{'bg-gray-200': hoveredCol === 0 || isRowInHoverRange(i, hoveredRow), 'bg-white': hoveredCol !== 0 && !isRowInHoverRange(i, hoveredRow)}"
                  (mouseenter)="setHoveredCell(i * (showBudget ? 5 : 4) + (showBudget ? 4 : 3), 0)"
                  (mouseleave)="clearHoveredCell()">
              </td>
              <td class="sticky-col second-col py-2.5 px-4 td-primary-b-border min-w-32"
                  [ngClass]="{'bg-gray-200': hoveredCol === 1 || hoveredRow === i * (showBudget ? 5 : 4) + (showBudget ? 4 : 3), 'bg-white': hoveredCol !== 1 && hoveredRow !== i * (showBudget ? 5 : 4) + (showBudget ? 4 : 3)}"
                  (mouseenter)="setHoveredCell(i * (showBudget ? 5 : 4) + (showBudget ? 4 : 3), 1)"
                  (mouseleave)="clearHoveredCell()">
                成長率
              </td>
              @for(data of place.productsData; track $index; let j = $index){
                <td class=" py-2.5 px-4 td-primary-b-border text-right min-w-32"
                    [ngClass]="{
                      'bg-gray-200': hoveredCol === j + 2 || hoveredRow === i * (showBudget ? 5 : 4) + (showBudget ? 4 : 3),
                      'bg-white': hoveredCol !== j + 2 && hoveredRow !== i * (showBudget ? 5 : 4) + (showBudget ? 4 : 3),
                      'font-bold': j === (place.productsData.length - 1)|| i === (tableData.length - 1)}"
                    (mouseenter)="setHoveredCell(i * (showBudget ? 5 : 4) + (showBudget ? 4 : 3), j + 2)"
                    (mouseleave)="clearHoveredCell()">
                  {{ data.growthRate | customPercent }}
                </td>
              }
            </tr>
          }
        }
      </tbody>
    </table>
  </div>
}
